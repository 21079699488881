import React, { useEffect, useState } from "react";
import Navbar from "../component/navbar";
import Banner from "../assets/svg/banner.svg";
import Line from "../assets/png/line.png";
import Blog from "../component/blog";
import Footer from "../component/footer";
import { ColorRing } from 'react-loader-spinner'


export default function Landing() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000); 

    return () => clearTimeout(timer);
  }, []);


  return (
    <>
      {loading ? (
        <ColorRing
          visible={true}
          height="150"
          width="150"
          colors={['#FB35FE', '#3DFAFA', '#FB35FE', '#3DFAFA', '#FB35FE']}
          ariaLabel="three-circles-loading"
          wrapperStyle={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
          wrapperClass=""
        />
      ) : (
        <>
          <Navbar />
          <div className="relative">
            <img src={Banner} alt="" />

            <img src={Line} alt="" className="block lg:hidden" />

            <div className=" bg-black lg:w-[50%] 2xl:w-[30%] lg:rounded-r-[45px] lg:absolute lg:bottom-0 p-2 md:p-5">
              <p className="text-center lg:text-start inter font-[800] text-[25px] md:text-[60px] lg:text-[45px] xl:text-[60px] italic bg-gradient-to-r from-[#FB35FE] to-[#3DFAFA] bg-clip-text text-transparent">
                Building Solutions <br /> For Resilient Humans
              </p>
            </div>
          </div>
          <img src={Line} alt="" />
          <Blog />
          <Footer />
        </>
      )}
    </>
  );
}
