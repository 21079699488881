import React, { useEffect, useState } from "react";
import Navbar from "../component/navbar";
import Line from "../assets/png/line.png";
import Privacycomp from "../component/privacy";
import Footer from "../component/footer";
import { ColorRing } from 'react-loader-spinner'


export default function Privacy() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000); 

    return () => clearTimeout(timer);
  }, []);


  return (
    <>
      {loading ? (
        <ColorRing
          visible={true}
          height="150"
          width="150"
          colors={['#FB35FE', '#3DFAFA', '#FB35FE', '#3DFAFA', '#FB35FE']}
          ariaLabel="three-circles-loading"
          wrapperStyle={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
          wrapperClass=""
        />
      ) : (
        <>
          <Navbar />
          <img src={Line} alt="" />
          <Privacycomp />
          <Footer />
        </>
      )}
    </>
  );
}
