import React, { Fragment, useState } from "react";
import Logo from "../assets/png/logo.png";
import { RiMenu3Fill } from "react-icons/ri";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";

export default function Navbar() {
  const [open, setOpen] = useState(false);

  return (
    <>
      <div className="grid grid-cols-2 lg:grid-cols-12 w-[95%] 2xl:w-[60%] mx-auto py-5">
        <div className="lg:col-span-3">
            <Link to="/">
          <img src={Logo} alt="" className="md:w-[70%] lg:w-auto cursor-pointer" />
          </Link>
        </div>
        <div className="lg:col-span-9 hidden lg:flex gap-10 self-center  justify-end">
          <div className="self-center flex gap-10">
            <p className="inter font-[700] text-[20px] text-white transition duration-500 ease-in-out  hover:text-[#3DFAFA] cursor-pointer">
            <Link to="/"> Home</Link>
            </p>
            <p className="inter font-[700] text-[20px] text-white transition duration-500 ease-in-out  hover:text-[#3DFAFA] cursor-pointer">
            <Link to="/about">About</Link>
            </p>
            <p className="inter font-[700] text-[20px] text-white transition duration-500 ease-in-out  hover:text-[#3DFAFA] cursor-pointer">
            <Link to="/solutions">Solutions</Link>
            </p>
            <p className="inter font-[700] text-[20px] text-white transition duration-500 ease-in-out  hover:text-[#3DFAFA] cursor-pointer">
            <Link to="/login">Login</Link>
            </p>
          </div>
          <Link to="/signup">
          <button className="inter font-[700] text-[20px] text-white bg-[#FB35FE] transition duration-500 ease-in-out hover:bg-[#3DFAFA] py-[14px] px-[24px] rounded-full">
            Sign Up
          </button>
          </Link>
        </div>
        <div className="block lg:hidden self-center ml-auto">
          <RiMenu3Fill
            onClick={() => setOpen(true)}
            className="text-[#3DFAFA] text-[30px] md:text-[35px]"
          />
        </div>
      </div>

      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative " onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-hidden  z-10">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-0">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto relative w-screen max-w-full">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-in-out duration-500"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in-out duration-500"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="absolute top-0 left-0 -ml-4 flex pt-4 pr-2 sm:-ml-10 sm:pr-4"></div>
                    </Transition.Child>
                    <div className="flex w-[70%] ml-auto h-full flex-col overflow-y-scroll  bg-black shadow-xl  relative z-40">
                      <div className=" container grid grid-cols-1  ">
                        <div className="flex justify-between px-2 border-b-[1px] border-[#3DFAFA] py-5 ">
                          <img src={Logo} alt="" className="w-[70%]" />

                          <div className="w-[50%] px-3"></div>
                          <button
                            type="button"
                            className=" rounded-md text-white  focus:outline-none"
                            onClick={() => setOpen(false)}
                          >
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon
                              className="h-8 w-8 text-[#3DFAFA]"
                              aria-hidden="true"
                            />
                          </button>
                        </div>

                        <div className="ml-0 p-5">
                        
                          <p className="inter font-[700] text-[20px] text-[#3DFAFA] hover:text-[#FB35FE] cursor-pointer mt-5">
                          <Link to="/"> Home</Link>
                          </p>
                          <p className="inter font-[700] text-[20px] text-[#3DFAFA] hover:text-[#FB35FE] cursor-pointer mt-3">
                          <Link to="/about">About</Link>
                          </p>
                          <p className="inter font-[700] text-[20px] text-[#3DFAFA] hover:text-[#FB35FE] cursor-pointer mt-3">
                          <Link to="/solutions">Solutions</Link>
                          </p>
                          <p className="inter font-[700] text-[20px] text-[#3DFAFA] hover:text-[#FB35FE] cursor-pointer mt-3">
                          <Link to="/login">Login</Link>
                          </p>

                          <p className="inter font-[700] text-[20px] text-[#3DFAFA] hover:text-[#FB35FE] cursor-pointer mt-3">
                          <Link to="/signup">Sign Up</Link>

                          </p>


                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
