import React from "react";
import Flogo from "../assets/png/f-logo.png";
import Icon from "../assets/png/icon.png";
import Icon2 from "../assets/png/icon2.png";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <>
      <>
        <p className="hidden lg:block cent font-[400] text-[30px] xl:text-[40px] text-white text-center w-[100%] md:w-auto mx-auto md:mx-0 mt-5">
          building resilient humans
        </p>
        <div className="grid md:grid-cols-2 lg:grid-cols-3 w-[80%] md:w-[75%] mx-auto mt-10 md:mt-14">
          <div>
            <Link to="/">
              <img
                src={Flogo}
                alt=""
                className="mx-auto md:mx-0 2xl:mx-auto w-[50%] md:w-[80%] lg:w-[40%] cursor-pointer"
              />
            </Link>
          </div>
          <div className="flex justify-center md:block gap-5 md:gap-0  ">
            <img
              src={Icon}
              alt=""
              className="2xl:mx-auto  w-[45%] md:w-[70%] 2xl:w-[40%] rounded-[10px] mt-5 cursor-pointer border-[2px] border-[#a6a6a6]"
            />
            <img
              src={Icon2}
              alt=""
              className="2xl:mx-auto w-[45%] md:w-[70%] 2xl:w-[40%] mt-5 cursor-pointer"
            />
          </div>
          <div className="text-center md:text-start">
            <Link to="/contactus">
            <p className="inter font-[400] text-[20px] text-white transition duration-500 ease-in-out  hover:text-[#3DFAFA] cursor-pointer mt-16 lg:mt-0">
              Contact Us
            </p>
            </Link>
            <Link to="/privacy">
            <p className="inter font-[400] text-[20px] text-white transition duration-500 ease-in-out  hover:text-[#3DFAFA] cursor-pointer mt-5">
              Privacy Statement
            </p>
            </Link>
            <Link to="/fda">
            <p className="inter font-[400] text-[20px] text-white transition duration-500 ease-in-out  hover:text-[#3DFAFA] cursor-pointer mt-5">
              FDA Statements
            </p>
            </Link>
            <Link to="/terms">
            <p className="inter font-[400] text-[20px] text-white transition duration-500 ease-in-out  hover:text-[#3DFAFA] cursor-pointer mt-5">
              Terms and Conditions
            </p>
            </Link>
            <Link to="/careers">
            <p className="inter font-[400] text-[20px] text-white transition duration-500 ease-in-out  hover:text-[#3DFAFA] cursor-pointer mt-5">
              Careers
            </p>
            </Link>
            <Link to="/support">
            <p className="inter font-[400] text-[20px] text-white transition duration-500 ease-in-out  hover:text-[#3DFAFA] cursor-pointer mt-5">
              Support
            </p>
            </Link>
          </div>
        </div>

        <div className="pt-14 lg:pt-10 pb-10 lg:pb-20">
          <p className="inter font-[600] text-[14px] text-white text-center">
            Copyright 2024 Resilient Labs Inc.
          </p>
          <p className="cent font-[400] text-[13px] text-white text-center w-[80%] md:w-auto mx-auto md:mx-0">
            Building Resilient Humans Since 2012
          </p>
        </div>
      </>
    </>
  );
}
