import React from "react";
import Line from "../assets/png/line.png";
import PLine from "../assets/png/pline.png";


import { GoDotFill } from "react-icons/go";

export default function Blog() {
  return (
    <>

      <div className="grid lg:grid-cols-2 w-[95%] 2xl:w-[60%] mx-auto mt-10 lg:mt-0 ">

        <div className="self-center xl:self-start xl:mt-14 w-[90%] lg:w-auto mx-auto lg:mx-0">



        </div>
      </div>

      <img src={PLine} alt="" className="" />
    </>
  );
}
