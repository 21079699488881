import "./App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "@fontsource/inter/400.css";
import "@fontsource/inter/500.css";
import "@fontsource/inter/600.css";
import "@fontsource/inter/700.css";
import "@fontsource/inter/800.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Landing from "./pages/landing";
import About from "./pages/about";
import Terms from "./pages/terms";
import FDA from "./pages/fda";
import Login from "./pages/login";
import Careers from "./pages/careers";
import Contactus from "./pages/contactus";
import Privacy from "./pages/privacy";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";


const firebaseConfig = {
  apiKey: "AIzaSyDjTYsA5SFW7e-nIvqZqhwNKKFs54JkeRo",
  authDomain: "resilientlabswebsite.firebaseapp.com",
  projectId: "resilientlabswebsite",
  storageBucket: "resilientlabswebsite.appspot.com",
  messagingSenderId: "675608490651",
  appId: "1:675608490651:web:42713050c26b5d55038c3e",
  measurementId: "G-D6JWDVCCHD"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);


function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/about" element={<About />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/fda" element={<FDA />} />
          <Route path="/login" element={<Login />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/contactus" element={<Contactus />} />
          <Route path="/privacy" element={<Privacy />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
