import React from "react";
import Line from "../assets/png/line.png";
import PLine from "../assets/png/pline.png";
import { GoDotFill } from "react-icons/go";
import { Link } from "react-router-dom";

export default function FDA() {
  return (
    <>

      <div className="grid lg:grid-cols-1 w-[75%] 2xl:w-[60%] mx-auto mt-10 lg:mt-0 ">
        <div className="flex flex-col items-center justify-center  xl:self-start xl:mt-14 w-[90%] lg:w-auto mx-auto lg:mx-0">
        <p className="inter font-[400] text-[20px] md:text-[25px] xl:text-[30px] text-white mt-3">RESILIENT LABS, INC. FDA STATEMENT
          </p>
          <p className="inter font-[400] text-[15px] md:text-[20px] xl:text-[25px] text-white mt-3">Last Updated: May 19, 2024
          </p>
          <p className="inter font-[400] text-[15px] md:text-[20px] xl:text-[25px] text-white mt-3">FDA Discalimer</p>
          <p className="inter font-[400] text-[20px] md:text-[25px] xl:text-[30px] text-white mt-3">Important</p>
          <p className="inter font-[400] text-[15px] md:text-[20px] xl:text-[25px] text-white mt-3">
These statements have not been evaluated by the Food and Drug Administration, MHRA, EMEA, or other medicinal products evaluation agency. Resilient Labs Inc. expressly makes no health or medical claims for our products at this time. Our products are not intended to diagnose, treat, cure or prevent any disease. Our products should be used only as directed on the packaging. Sale to minors are prohibited.
</p>
  
<p>The Halo Mask Smart PPE IoT Medical Inhalants Biotech DoD Department of Defense DARPA n95 Smart Mask technology</p>
<p>The Halo Mask Smart PPE IoT Medical Inhalants Biotech DoD Department of Defense DARPA n95 Smart Mask technology</p>
<p>The Halo Mask Smart PPE IoT Medical Inhalants Biotech DoD Department of Defense DARPA n95 Smart Mask technology</p>
<p>The Halo Mask Smart PPE IoT Medical Inhalants Biotech DoD Department of Defense DARPA n95 Smart Mask technology</p>


        </div>
      </div>

      <img src={PLine} alt="" className="" />
    </>
  );
}
