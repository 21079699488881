import React from "react";
import Line from "../assets/png/line.png";
import PLine from "../assets/png/pline.png";
import { Link } from "react-router-dom";

export default function Contactuscomp() {
  return (
    <>
    <div className="form-container">
        <div className="wrapper">
        <h2>Contact Us</h2>
        <div className="form-section">
            <div className="form-details">
            </div>

            <div className="input-details">
            </div>
        </div>
        </div>
    </div>
      <img src={PLine} alt="" className="" />
    </>
  );
}
