import React from "react";
import Line from "../assets/png/line.png";
import PLine from "../assets/png/pline.png";
import { GoDotFill } from "react-icons/go";
import { Link } from "react-router-dom";

export default function Privacycomp() {
  return (
    <>

      <div className="grid lg:grid-cols-1 w-[75%] 2xl:w-[60%] mx-auto mt-10 lg:mt-0 ">
        <div className="self-center xl:self-start xl:mt-14 w-[90%] lg:w-auto mx-auto lg:mx-0">
        <p className="inter font-[400] text-[15px] md:text-[20px] xl:text-[25px] text-white mt-3">RESILIENT LABS, INC PRIVACY POLICY
          </p>
          <p className="inter font-[400] text-[15px] md:text-[20px] xl:text-[25px] text-white mt-3">Last Updated: May 19, 2024
          </p>
          <p className="inter font-[400] text-[15px] md:text-[20px] xl:text-[25px] text-white mt-3">Welcome to the Resilient Labs, Inc Privacy Policy!
          </p>
          <p className="inter font-[400] text-[15px] md:text-[20px] xl:text-[25px] text-white mt-3">Resilient Labs, Inc. (together with our affiliates, “Halo 1”, "Resilient Labs, Inc", "we", or "us"). This Privacy Policy is meant to help you understand how we collect, use and share information to operate, improve, develop and help protect our services, or as otherwise outlined in the Policy. This includes how we collect and process your personal information such as when you visit Resilient Labs, Inc. sites, use any Resilient Labs, Inc. services including Resilient Labs, Inc. applications (the "App") or wellness equipment (such as the Resilient Labs, Inc Halo, Halo +, or Resilient Labs, Inc. Wellness Equipment), or when you interact with us via our call centers, support service including live chats and e-mail communications, social media channels, studios or retail shops including our showrooms or concession stores as well as other online and offline services we provide (together, the "Services"). This Privacy Policy applies to our Services except where otherwise noted.
          </p>
          <p className="inter font-[400] text-[15px] md:text-[20px] xl:text-[25px] text-white mt-3">Why This Matters</p>
          <p className="inter font-[400] text-[15px] md:text-[20px] xl:text-[25px] text-white mt-3">
          Resilient Labs, Inc uses technology, formulas and designs to connect the world through wellness and empower our customers to be the best version of themselves. Our goal with this Privacy Policy is in line with our mission to empower our members with the knowledge and understanding of what information Resilient Labs, Inc. collects, uses and shares transparently.
Please be sure to read this entire Privacy Policy before using or submitting information through the Services. By using or submitting information through the Services, you are acknowledging the collection, transfer, manipulation, processing, storage, disclosure and other uses of your personal data as described in this Privacy Policy.

          </p>
          <p className="inter font-[400] text-[15px] md:text-[20px] xl:text-[25px] text-white mt-3">CONTENTS:</p>
          <p className="inter font-[400] text-[15px] md:text-[20px] xl:text-[25px] text-white mt-3">
            <ul>
              <li><Link to="/terms#whatcollected">WHAT PERSONAL INFORMATION WE COLLECT</Link></li>
              <li>WHY WE USE PERSONAL INFORMATION</li>
              <li>WHO WE SHARE YOUR PERSONAL INFORMATION WITH</li>
              <li>WELLNESS DATA</li>
              <li>INTERNATIONAL DATA TRANSFERS</li>
              <li>YOUR PRIVACY RIGHTS AND PREFERENCES</li>
              <li>DATA RETENTION</li>
              <li>SECURITY OF YOUR INFORMATION</li>
              <li>LEGAL BASIS FOR PROCESSING</li>
              <li>CHILDREN’S PRIVACY</li>
              <li>U.S. STATES PRIVACY NOTICE</li>
              <li>HOW TO CONTACT US</li>
              <li>UPDATES TO OUR PRIVACY POLICY</li>
            </ul>
          </p>

          <p id= "whatcollected" className="inter font-[400] text-[15px] md:text-[20px] xl:text-[25px] text-white mt-3">1. WHAT PERSONAL INFORMATION WE COLLECT</p>
          <p className="inter font-[400] text-[15px] md:text-[20px] xl:text-[25px] text-white mt-3">           
Depending on how you interact with Resilient Labs, Inc and our Services, we may collect your personal information. The sources from which we collect this personal information fall into three categories: Information you provide, Information we collect automatically, Information we collect from other sources. See below for more information on the types of personal information in each category.
</p>
<p className="inter font-[400] text-[15px] md:text-[20px] xl:text-[25px] text-white mt-3">   1.1. Information you provide</p>
<p className="inter font-[400] text-[15px] md:text-[20px] xl:text-[25px] text-white mt-3">When you interact with Resilient Labs, Inc you may volunteer personal information, or you may be asked to provide personal information in order for us to provide the Services as described. Examples of interacting with Us: accessing our Services, completing and/or using one of our registration forms, reviewing our content, contacting us with inquiries, responding to one of our surveys, purchasing or using our Services.
Standard Identifying Information. Name, mailing address (including zip code), billing address (including zip code), email, date of birth and phone number. These are collected when you register for Services, subscribe to Resilient Labs, Inc marketing, communicate with us, or register for a test ride. Please note, If you provide a name in your Profile, it will be Public and Members can search for you by name, unless you have toggled on “Hide me from search” in Preferences. If you add your phone number to your Profile, it can be used to suggest you to Members who have your phone number in their mobile device, unless you have toggled on “Hide me from contacts syncing” in Preferences.
Purchase Information. Details related to products and Services you order, and delivery information (including billing, shipping and delivery address) that you provide when you purchase products and Services. Please note, we do not store or capture any credit card information. This information is collected directly by our payment processing partners, but we would like to inform you about its collection since this information is collected from you through our site and from you, our customer.
Profile Information. Information that you provide in connection with your Resilient Labs, Inc Profile, such as username, and any additional information you choose to provide to enhance your use of the Services, including your weight, height, gender, general location, photo, picture, tags, image, avatar and the Standard Identifying Information listed above. Please note your name, username, tags, location (if you add it) and profile image (if you add one) will always be Public.
Contact Information. If you choose to sync your Contacts from your mobile device Resilient Labs, Inc will access, import and store the name, email and phone number of your Contacts in order to show Contacts which are currently on Resilient Labs, Inc, suggest others we think you may know to build the Resilient Labs, Inc community, and to make it easy for you to invite your Contacts to join Resilient Labs, Inc. 
Geolocation Data. We will only have your location if you choose to allow your mobile device to send us precise location information. If you choose to share your location, depending on how you use the Services and your device settings, type and connectivity, we may collect location-based information including your IP address, GPS location, city, county, zip code and region and your smart device’s proximity to “beacons”, Bluetooth networks and/or other proximity systems. You will have the ability to opt-in or out of our collection of location information by selecting the location and Bluetooth settings in your device but this may affect your ability to use certain location dependent Services (such as Pollen or Air Quality).
Physical Characteristics. Some physical characteristics are collected in connection with our Services when you consent to its use; physical characteristics are not used to identify you. Depending on the privacy regulations in your jurisdiction, some of these physical characteristics may fall under the definition of biometric information.
Your Image or Likeness: The Resilient Labs, Inc Halo App contains microphone and voice control features. These features are not used for identification purposes. These features are in use only when activated by you, for example, to use the Resilient Labs, Inc Halo, You device to take a Resilient Labs, Inc user profile photo and, for usage of the Resilient Labs, Inc Halo App, any device settings that you have activated.
Your Voice: We may use your voiceprint to understand your voice commands and, if you opt in, to improve our abilities to understand voice commands in general when you are using Resilient Labs, Inc Halo. This data is not used for identification purposes nor is it stored by Resilient Labs, Inc, but may be shared with Service Providers (defined below) to provide the Services to you. Other Services currently do not collect voice recordings at this time.
Wellness Characteristics: Information you provide in order to track your performance while using our Services, e.g. height, weight, heart rate (if a compatible heart rate monitor is connected). This may include data from other compatible devices or services.
User Generated Content. Any content or material you publish or post such as hashtags, photos or videos on our Services as described in Section 8 “User Content” of our Terms of Service.
Connected Devices/Accounts. We enable you to connect your Resilient Labs, Inc account with third parties (i.e. devices, apps, APIs, or websites that integrate with the Services). Where you choose to connect another company's device or account to your account, or engage in our Services that features a partnership with a third party, we may, with your consent, collect information from your use of the connected device(s) to facilitate interaction with our Services. Activity trackers are an example of a connected device, and music streaming is an example of a connected account. Information collected by these third parties is subject to their terms and policies and may not provide you with the same privacy controls that are available on Resilient Labs, Inc.
Feedback and Support Communications. Any details of your communications/interactions with us, including when you call, email, live chat, video call or text with our support teams or communicate with us via social media channels or community forums. We may record support calls for training and monitoring purposes and to help resolve issues. We will tell you before recording and, where required by applicable laws, obtain your consent for the collection of these recordings. Our live chat Service Provider records information submitted in the chat bot, as well as any dialogue with member support. We collect, retain, and use Communication Information based upon our business need to receive and act upon your feedback or issues and for training purposes.
Surveys and Review Information. Any information you voluntarily provide to us or our Service Providers (or otherwise publish) in connection with a survey you complete or review / testimonial you provide about Resilient Labs, Inc goods and/or Services. We may collect this information to understand your opinions, better market our services and to collect relevant information about our business.
Promotions, Sweepstakes and Competition Information. Any information that you provide to us in connection with any promotions, sweepstakes and/or competitions we run, including for example, responses to competition questions.
Sensitive Personal Information. We will only process Sensitive Personal Information where it is necessary, and where you give consent, for the purposes of providing Resilient Labs, Inc Services, carrying out our legal obligations or exercising specific rights as permitted by law. The definition of Sensitive Personal Information depends on jurisdiction and where you are located, but geolocation, race, sexuality, and religion are examples of what may be considered sensitive in some locations. Please also review section 7.1 below.
We ask that you not disclose Sensitive Personal Information to us through or in connection with our Services unless we have explicitly requested such disclosure from you.
Other Information. Any other information that you may provide to us in connection with your use of Resilient Labs, Inc products or Services, visits to Resilient Labs, Inc studios or retail shops and / or other contact that you have with the Resilient Labs, Inc team.
</p>
<p className="inter font-[400] text-[15px] md:text-[20px] xl:text-[25px] text-white mt-3"> 
1.2. Information that we collect automatically</p>
<p className="inter font-[400] text-[15px] md:text-[20px] xl:text-[25px] text-white mt-3"> Contact Information. If you choose to sync your Contacts via your mobile device, as described above, we will continue to automatically sync them on a periodic basis until you turn off “Contacts syncing” in the Privacy section of Preferences on your mobile device, which will stop synching and delete your Contacts information from our system.
Information generated through use of the Services. This includes activities such as services registered for and participated in, Resilient Labs, Inc members you are following and who are following you, hashtags that you are following, details of wellness performances such as leaderboard rank, total wellness output, workout speed, distance, calories burned and accuracy of metrics/pace compliance, wellness level and difficulty, and, if you have chosen to connect a heart rate monitor, your workout heart rate or other biomarker device.
Wellness Data. Information relating to your fitness performance and/or workouts, including things like, strive score, calories burned, distance run, length of workout, and heart rate (if you connect a heart monitor). For more information see Section 5 below. We do not collect health or medical information, however, some privacy-related legislation may classify some of our wellness or accessibility offerings as health-related information. Some examples: pregnancy-related metrics, some meditation wellness metrics, data related to your mood. Please note that these wellness markness will be seen if your profile is public, and seen by any follower you allow to view your private profile.
Inferences. We may collect data and make inferences based on that data, but the inferences are not used to infer specific characteristics about you other than what workouts or classes you may enjoy. For more information see Section 5 “Fitness Data” below.
Information from your device. Device Data: Information from and about the device you use when you use our Services, including IP address, identifiers, type, mobile carrier, browser type and language, operating system, and location (if enabled, see Geolocation Data above).
Usage Data: We may also collect information about how your device interacts with our Services, such as the content you access, pages and sites visited before and after you reach the Services, the date and time of your use, the amount of time spent on the Services, and other actions taken through use of the Services such as preferences. We use data analytics with this Usage Data to better understand your use of the Services in order to improve the Services offered. For more information see Section 4 “Cookies, Analytics and Similar Tracking Technology” below.
Internet or Other Network Activity.Information including browsing or search history, including interactions with our websites, mobile applications, marketing and advertising. For more information see Section 4 “Cookies, Analytics and Similar Tracking Technology” below.
Location Information. If you choose to share your location we may collect location-based information including your IP address, GPS location, city, county, zip code and region and your smart device’s proximity to “beacons”, Bluetooth networks and/or other proximity systems. You will have the ability to opt-in or out of our collection of location information by selecting the location and Bluetooth settings in your device but this may affect your ability to use certain location dependent Services (such as track your run functionality). Some of this information may be collected using Cookies and Similar Technologies as described below.
</p>
<p className="inter font-[400] text-[15px] md:text-[20px] xl:text-[25px] text-white mt-3"> 1.3. Information we collect from other sources
</p>
<p className="inter font-[400] text-[15px] md:text-[20px] xl:text-[25px] text-white mt-3"> Other Applications. If you access our Services through a third party application, such as the Apple App Store or Google Play App Store (together with any similar applications, "App Stores") or Social Networking Sites ("SNS") such as Instagram, Twitter or Facebook, we may collect information about you from that Third-Party application that you have made public via your privacy settings. This may include: your name, your SNS user ID, your App Store user name, location, sex, birth date, email, profile picture and your contacts on the SNS
Security. Security and Safety: We may collect certain information in order to protect and verify your information and our Services. This may include Device IP, Location, and other identifiers with a third party application to ensure your safety and the security of your account.
Other Sources. We may receive information about you from other sources to supplement information provided by you. The supplemental information allows us to enhance our ability to provide you with information about our business, products and Services and to provide you with any marketing communications that you have provided your consent to receive. Please see “Your Privacy Rights and Preferences” below for how to unsubscribe.
</p>
<p className="inter font-[400] text-[15px] md:text-[20px] xl:text-[25px] text-white mt-3"> This may include:
</p>
<p className="inter font-[400] text-[15px] md:text-[20px] xl:text-[25px] text-white mt-3"> Marketing such as where we are conducting a joint marketing campaign with a selected partner and you have provided consent for us to receive your information or data resellers whose data may be used for research or marketing purposes.
</p>
<p className="inter font-[400] text-[15px] md:text-[20px] xl:text-[25px] text-white mt-3"> Publicly available sources
</p>
<p className="inter font-[400] text-[15px] md:text-[20px] xl:text-[25px] text-white mt-3"> Partners we work with may, with your consent, collect information from your use of other connected devices, such as those made by other companies, to interact with our Services, such as activity trackers.
Financial sources such as credit bureaus and certain generalized credit ratings, with your consent, in order to provide rental or other Services to you.
</p>
<p className="inter font-[400] text-[15px] md:text-[20px] xl:text-[25px] text-white mt-3"> 2. WHY WE USE PERSONAL INFORMATION
</p>
<p className="inter font-[400] text-[15px] md:text-[20px] xl:text-[25px] text-white mt-3"> We use your information for the following purposes:
</p>
<p className="inter font-[400] text-[15px] md:text-[20px] xl:text-[25px] text-white mt-3"> To provide you with the Services
<ul>
  <li> To provide you with the Services</li>
  <li>To build community</li>
  <li>To communicate with you</li>
  <li>To send you marketing and/or promotions</li>
  <li>For analytics, research and product and Service improvement purposes</li>
  <li>For quality assurance and training purposes</li>
  <li>For personalisation and advertising</li>
  <li>For personalisation and analytics</li>
  <li>To ensure the security and integrity of our Services</li>
  <li>To comply with any applicable law and/or regulations</li>
  <li> For hiring purposes</li>
  <li>For safeguarding and defending our vital interests and exercising any rights, asserting any claims any other lawful business purpose</li>
  <li>To provide you with the Services.</li>
</ul>
</p>

<p className="inter font-[400] text-[15px] md:text-[20px] xl:text-[25px] text-white mt-3">
Resilient Labs, Inc uses data to operate, improve, maintain and personalize our Services. 
</p>
<p className="inter font-[400] text-[15px] md:text-[20px] xl:text-[25px] text-white mt-3">We use data to:
</p>
<p className="inter font-[400] text-[15px] md:text-[20px] xl:text-[25px] text-white mt-3">
  <ul>
    <li>Verify your identity, set up and maintain your account;</li>
    <li>Process payments and fulfill product orders;</li>
    <li>Provide access to class bookings and participation, workout tracking</li>
          <li>performance and statistics;</li>
          <li>Communicate and track your wellness among other Resilient Labs, Inc Members; and</li>
          <li>Maintain our services and operations.</li>
  </ul>
</p>
<p className="inter font-[400] text-[15px] md:text-[20px] xl:text-[25px] text-white mt-3">To build community. Resilient Labs, Inc uses Contact information provided by Members to make it easy for Members to connect with people they know and to invite others to the Resilient Labs, Inc community.
</p>
<p className="inter font-[400] text-[15px] md:text-[20px] xl:text-[25px] text-white mt-3">To communicate with you. Supporting and communicating with you when you require it such as for:

Administrative matters (such as account management, updates to terms, or system maintenance); 
To provide you with information about our Services (such as product delivery and logistics, servicing and technical support); and
To respond to any questions, comments or requests you have about our Services.

To send you marketing and/or promotions. Resilient Labs, Inc uses data to market its Services, which may include:

Contacting you by phone, email, postal mail, push notifications, in-app messages or SMS with information about news, offers, products, promotions, events, contests and/or Services that might be of interest to you, as permitted by applicable law. This may include various usage data to send marketing communications that are personalized. Please see the “Your Privacy Rights and Preferences” section below for how to unsubscribe from marketing.
For analytics, research and product and Service improvement. Resilient Labs, Inc works everyday to improve your experience by using data:
Including monitoring and measuring your use of our products and Services and conducting research/surveys, to understand how they are being used and how they can be improved;
Financial analysis and analytics to understand billing, pricing and other financial matters that can be improved; and
In some cases, research may be deidentified, compiled and analyzed on an aggregate basis, we may share this aggregate data with our affiliates, agents, service providers and other third parties with whom we do business. This includes, but is not limited to for example usage, performance, product, and music. This aggregate information does not identify you personally.
For quality assurance and training purposes. Resilient Labs, Inc puts members first and we use data to further that value by:
Monitoring communications and interactions with you to ensure the quality of our support services, appropriate training of our people and to resolve your complaints. We will provide notice and consent options for monitoring of communications as required by applicable law.
For personalisation and advertising. Resilient Labs, Inc uses data for personalisation and advertising that may include:
Providing you with a personalized and tailored Service, to serve you with Resilient Labs, Inc advertising on third party properties;
To serve you with relevant advertising and content on our Services;
Understanding and assessing the effectiveness of our ads displayed; and
We may contract with third-party advertising companies and social media companies to display ads on their service. These companies may use cookies and similar technologies to track your activity, the information collected by these third parties in this manner is subject to that third party’s own privacy policies. We may also use aggregated information from these sources for creating similar audiences in order to better target advertisements on their networks to potential consumers who appear to have shared characteristics.

For personalisation and analytics.
To provide wellness recommendations to you based on our inferences about your preferences and practices;
To improve our services and product offerings;
To understand how you interact with our websites, products, mobile app, advertisements, and communications with you;
To determine which of our products or services are the most popular; to improve our products and services, and marketing campaigns;
To personalize your experience; to understand how you use our products and services; to create a more personalized experience; to provide services;
To better understand our Member needs; and
To provide personalized recommendations about our products and services.
To ensure the security and integrity of our Services. Providing a safe and secure environment is key to providing a responsible platform and we do this by using data to:
Detect and combat any abuse or any harmful activity, fraud, spam, and illegal activity affecting our Services such as using personal identifiers, user profile, data usage, and other types of data to detect fraud, authentication or other harmful activity;
Monitor CCTV images, the prevention & detection of crime and for the health and safety of individuals on Resilient Labs, Inc premises.
To comply with any applicable law and/or regulations, legal proceedings. We use data to investigate disputes or claims related to our Services. In certain circumstances, we may be obligated to use your information to respond to legal, government or regulatory requests. We may use the IP address associated with your log in to the Services to determine your general location in order to comply with global privacy regulations and serve you necessary notifications and/or consent mechanisms.
For safeguarding and defending our vital interests and exercising any rights, asserting any claims and any other lawful business purpose. This includes any incidental business purpose related to or in connection with any of the above, or for any other purpose disclosed to you at the point at which we collect your personal information.
</p>
<p className="inter font-[400] text-[15px] md:text-[20px] xl:text-[25px] text-white mt-3">
3. WHO WE SHARE YOUR PERSONAL INFORMATION WITH
</p>
<p className="inter font-[400] text-[15px] md:text-[20px] xl:text-[25px] text-white mt-3">
We may disclose your personal information to the following categories of recipients:
</p>
<p className="inter font-[400] text-[15px] md:text-[20px] xl:text-[25px] text-white mt-3">
Group Companies. We may share your personal information with other Resilient Labs, Inc related companies and our group for purposes consistent with this Privacy Policy. Resilient Labs, Inc has in place an intra-group data transfer agreement which covers the transfer of personal data on an intra-group basis in accordance with European Union, UK and Australian data protection laws.
Service Providers. Third Party Service Providers and Business Partners: We may share your personal information with our third party service providers (such as those who provide IT, analytics, payment processing, credit bureaus and sales, cloud storage providers, technical support or delivery services), third party business partners who otherwise process personal information for purposes that are described in this Policy (such as advertising and marketing partners, research partners, consultants) or who are otherwise disclosed to you when we collect your personal information (such as financing partners if you select this option during the checkout process). We may disclose aggregated, anonymous information to Service Providers. This information is not linked to personal information that can identify you or another individual person. Amongst other reasons, this information may be disclosed for analytics purposes. Resilient Labs, Inc does not share data with any providers and partners without a relevant data protection agreement or contractual language in place.
Third Party Apps that you link to your Resilient Labs, Inc account. As part of the functionality of our Services, we enable you to connect your Resilient Labs, Inc account with third party applications such as Fitbit®, Apple®, Strava®, Facebook® and Spotify® and similar applications. If you choose to connect your Resilient Labs, Inc account, we may share certain personal information with the paired application depending on what information you choose to share with such third party apps or have otherwise been notified will be shared during the pairing process. The information may include information generated through your use of the Services (i.e. workout information) and other contact and profile information. The processing of your personal information by such third party applications shall be governed by the privacy policies of those third parties. If you choose to connect your Resilient Labs, Inc account, we may also share certain information with other Resilient Labs, Inc members who have accounts with the same third party. For example, if you connect your Resilient Labs, Inc account to Facebook, your mutual Facebook friends may be alerted through our Services that you are also a Resilient Labs, Inc member.
Other Users of our Services. Depending on your Profile settings and how you interact with our Services, some of your personal information may be available for other Resilient Labs, Inc Members to see. Profiles are set to Public by default and Members can search for other Members by username and name (if you include name in your Profile). Some fields in your Resilient Labs, Inc profile -- name, gender, location, height, and weight -- are optional. Any information you provide in the First Name and/or Last Name fields,, will always be Public and searchable by other Members to find you. Anything you put in the location field is also always Public.
Photos uploaded to appear in a profile picture will show regardless of your profile status. In other words, if you have a Private profile people will be able to see the content of your profile picture in various places, but not other account details. For example, your profile picture will appear on the leaderboard, “breathe board” section and/or other areas.
</p>
<p className="inter font-[400] text-[15px] md:text-[20px] xl:text-[25px] text-white mt-3">
Profile Settings:
</p>
<p className="inter font-[400] text-[15px] md:text-[20px] xl:text-[25px] text-white mt-3">
Regardless of profile settings, the following are always Public: Name, username/leaderboard name, profile picture, location, tags and badges.
</p>
<p className="inter font-[400] text-[15px] md:text-[20px] xl:text-[25px] text-white mt-3">
Public Profile: If your profile is not set to Private, other registered Resilient Labs, Inc users will be able to view the information associated with your Profile, including your workout streaming history, activity feed and Outdoor Route (if you choose to share it).
</p>
<p className="inter font-[400] text-[15px] md:text-[20px] xl:text-[25px] text-white mt-3">
Private Profile: If your profile is Private, the image you choose for your profile picture will be visible to all users, but only Members you approve can see your profile details and fitness performance history. Followers you approve are able to see your workout streaming history. Please note, if you change your profile from Private to Public, all of your history will be visible.
</p>
<p className="inter font-[400] text-[15px] md:text-[20px] xl:text-[25px] text-white mt-3">
Other Profile Settings:
</p>
<p className="inter font-[400] text-[15px] md:text-[20px] xl:text-[25px] text-white mt-3">
Hide me from search: This preference controls whether or not a Member may search for you by name or username. When turned on, you will not be searchable.
</p>
<p className="inter font-[400] text-[15px] md:text-[20px] xl:text-[25px] text-white mt-3">
Hide me from contacts syncing: This preference controls whether or not Resilient Labs, Inc will suggest you to Members who have any of your Contact information (name, email and/or phone number) in their mobile device. When turned on you will not be shown to other Members when they sync their mobile device Contacts.
</p>
<p className="inter font-[400] text-[15px] md:text-[20px] xl:text-[25px] text-white mt-3">
Outdoor Travel Tracking: You have the ability to allow Resilient Labs, Inc to track and display your travel routes. Your travel routes are set to private by default, which means that only you can see them in your workout history. For more information, review the Private Travel Routes section in our Privacy Settings article for information about how to change this preference and this GPS article on your mobile device settings.
Competent Law Enforcement Bodies, Regulators, Government Agencies, Courts or other Third Parties. We may share your personal information, including CCTV images, with competent law enforcement bodies, regulators, our insurers, external legal advisors, government agencies, courts or other third parties where we believe disclosure is necessary: (i) to comply with applicable law; (ii) to exercise, establish or defend our legal rights (including to collect amounts owed to Resilient Labs, Inc); (iii) to protect your rights or vital interests or those or any other person; or (iv) to detect, prevent or otherwise address security, fraud or technical issues.
Potential Buyers (and their agents and advisors). We may share your personal information with actual or potential buyers (and their agents and advisors) in connection with any actual or proposed purchase, merger, acquisition, financing, reorganization, bankruptcy, receivership, sale of company assets or transition of service to another provider, provided that we inform the buyer or transferee that it must use your personal information only for the purposes disclosed in this Privacy Policy.
<p className="inter font-[400] text-[15px] md:text-[20px] xl:text-[25px] text-white mt-3">
4. COOKIES, ANALYTICS AND SIMILAR TRACKING TECHNOLOGIES
</p>
<p className="inter font-[400] text-[15px] md:text-[20px] xl:text-[25px] text-white mt-3">
We use cookies and similar tracking technologies such as pixel tags/web beacons and social media widgets (collectively "Cookies") to collect and use personal information about you, including for analytics purposes and to serve you interest-based advertising. For example, cookies are used to help keep track of items you put into your shopping cart including when you have abandoned your cart and this information is used to determine when to send cart reminder messages via SMS (see also Section 7.2 SMS Messaging). 
We may also use device identifiers, web storage, third-party-provided analytics services, such as session replay services, and other similar technologies and services, to collect information about your interactions with our content and Services. Session replay services consist of an analytics application that allows us to capture and analyze your interaction with our Services to better identify and repair any technical errors and optimize our Services. Such technologies and third-party-provided services may observe or record your activities when using our Services, including certain movements, scrolling, visit duration, clicks, and other interactions. Certain analytics may be removed via our privacy cookie controls.
Please also note that you may opt out and we honor certain technologies broadcasting an Opt-Out Preference Signal, such as the Global Privacy Control (GPC) depending on the state you are in. This occurs on the browsers and/or browser extensions that support such a signal. This request will be linked to your browser identifier only.
</p>
<p className="inter font-[400] text-[15px] md:text-[20px] xl:text-[25px] text-white mt-3">
5. WELLNESS DATA
</p>
<p className="inter font-[400] text-[15px] md:text-[20px] xl:text-[25px] text-white mt-3">
"Wellness Data" means any information relating to your overall wellness, fitness performance and/or workouts, including things like calories burned, distance covered, length of workout, and heart rate or other biomarker data if you choose and give your consent. We do not use or process your Wellness Data for identification purposes.
</p>
<p className="inter font-[400] text-[15px] md:text-[20px] xl:text-[25px] text-white mt-3">
Why we process your information?
</p>
Analyze your performance: As part of our core offering, we use Wellness Data to compare your past and current performance and to provide you with analytics about your progress over time.
Provide recommendations: We may recommend workouts, travel routes, Halo usage or other recommendations to you based on our inferences about your preferences and practices previous wellness goals. Recommendations include information derived from Wellness Data we collect from you.
Resilient Labs, Inc will not disclose, redisclose, or otherwise disseminate Wellness Data unless:
Authorized by you or your authorized representative; for example, if you pair a third party fitness tracker to your Resilient Labs, Inc device, you are authorizing the disclosure of your Fitness Data to the third party device;
In aggregated and anonymized formats to assist us and our service providers, vendors or partners in providing services to you;
Required pursuant to a valid warrant or subpoena issued by a court of competent jurisdiction; or
As required under applicable law, necessary for the establishment, exercise or defense of legal claims of wherever courts are acting in their judicial capacity.
</p>
<p className="inter font-[400] text-[15px] md:text-[20px] xl:text-[25px] text-white mt-3">
6. INTERNATIONAL DATA TRANSFERS
</p>
<p className="inter font-[400] text-[15px] md:text-[20px] xl:text-[25px] text-white mt-3">
Your personal information may be transferred to, processed and stored in countries other than the country in which you are resident, including the United States, Australia, Canada, the European Union and UK. The recipients of such information may be located in any country where Resilient Labs, Inc has a place of business and additional territories where our suppliers and partners may transfer data, details of which can be provided upon request.
We take appropriate safeguards to protect your personal information in accordance with this Privacy Policy specifically in relation to any international transfer. This includes implementing an intra-group data sharing agreement that includes safeguards and protections for any use of your personal information by our group entities and incorporates the European Commission's Standard Contractual Clauses, which require all group companies to protect relevant information in accordance with European Union and UK data protection laws.
We have implemented similar appropriate safeguards and comply with applicable legal requirements when transferring Personal Information to countries such as utilizing Binding Corporate Rules, Standard Contractual Clauses, and other data transfer mechanisms and/or rely on the European Commission adequacy decisions for certain countries outside of the European Economic Area should it be applicable with our third party service providers and partners. Further details can be provided upon request.
</p>
<p className="inter font-[400] text-[15px] md:text-[20px] xl:text-[25px] text-white mt-3">
7. YOUR PRIVACY RIGHTS AND PREFERENCES
</p>
<p className="inter font-[400] text-[15px] md:text-[20px] xl:text-[25px] text-white mt-3">
At Resilient Labs, Inc, we put Resilient Members first and respect your ability to exercise your privacy rights and choices. Where possible, we give you control over the personal data we collect about you, to ensure it is accurate and reflects your preferences. Depending on the country or U.S. state in which you are located, we respect your ability to know, access, correct, transfer, restrict the processing of, and delete your personal data. Please note that those rights do not always apply and there are certain exceptions to them.
Please also be advised that we continue to maintain some of your personal data where permitted by law, and that such information has not been deleted from Resilient Labs, Inc systems. We retain your personal information where it is necessary for us to:
Complete the transaction for which the personal information was collected, fulfill the terms of a written warranty or product recall conducted in accordance with laws, provide a good or service that you have requested, or reasonably anticipated within the context of our ongoing business relationship, or otherwise perform a contract between you and Resilient Labs, Inc;
Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity; or prosecute those responsible for that activity;
Debug to identify and repair errors that impair existing intended functionality;
Comply with a legal obligation, existing warrant, litigation hold.
Please note that if you Delete your account this will result in the deletion of your Resilient Labs, Inc account and all associated information, including workouts and achievements.
We will also confirm your identity before acting on your requests. We may not be able to respond to your request or provide you with personal data if we cannot verify your identity, or the authority to make the request on someone else’s behalf.
</p>
<p className="inter font-[400] text-[15px] md:text-[20px] xl:text-[25px] text-white mt-3">
7.1 Consent
</p>
<p className="inter font-[400] text-[15px] md:text-[20px] xl:text-[25px] text-white mt-3">
If we have collected and process your personal information with your consent (including any sensitive or special category data), then you can withdraw your consent at any time by using settings on the Resilient Labs, Inc. product or App (as applicable), via settings on your device or by contacting us using the details provided under How To Contact Us. Please note, such withdrawal will not affect the lawfulness of the processing prior to your withdrawal.
</p>
<p className="inter font-[400] text-[15px] md:text-[20px] xl:text-[25px] text-white mt-3">
7.2 Direct Marketing
</p>
<p className="inter font-[400] text-[15px] md:text-[20px] xl:text-[25px] text-white mt-3">
We may send marketing communications for purposes disclosed to you (including as specified in section 2 above) and, where required by applicable law, with your consent. You can unsubscribe from direct marketing at any time by following the instructions below or by contacting us using the details provided under the How To Contact Us. We will process your request within a reasonable time after receipt, in accordance with applicable laws. Unsubscribe options are provided in electronic marketing communications as required by applicable law.
Email and Telephone. You can unsubscribe from email communications at any time. To stop receiving our promotional emails, follow the unsubscribe instructions in the email messages you receive from us, submit an “Object to Marketing” request via our Privacy Request Form. For other options, review the How To Contact Us section below. We will process your request as soon as practicable after receipt, in accordance with applicable laws. Note that you will continue to receive transaction-related emails regarding products or services you have requested. We may also send you certain non-promotional communications regarding Resilient Labs, Inc and our Services and, to the extent permitted by law, you will not be able to opt-out of those communications (e.g., communications regarding updates to our Terms or this Privacy Policy).
SMS Messaging. Account Related Texts. If you opt in, you will receive text messages from Resilient Labs, Inc. “Halo Account” text messages are non-promotional messages from Resilient Labs, Inc alerting you to important information, like the details of your delivery, failed subscription payments and other alerts related to your Resilient Labs, Inc subscription or purchases. To opt-out of receiving “Halo Account” text messages, text STOP to the number from which you received the message (73586 in the US). We will send you one final message to confirm that you have been unsubscribed, and will process your request within a reasonable time after receipt, in accordance with applicable laws. If you experience any issues with text messages from Resilient Labs, Inc, text HELP to the relevant number referenced above or email our Support team at <a href="mailto:support@resilient-labs.com?body=Support Question">support@resilient-labs.com</a>. Carriers are not liable for delayed or undelivered messages. Message frequency varies. Message and data rates may apply.
Marketing Related Texts. If you opt in to “Resilient Labs, Inc” or “Halo” text messages, you will receive text messages from Resilient Labs, Inc Marketing. “Resilient Labs, Inc” or “Halo” text messages are recurring automated promotional and personalized marketing text messages (e.g. welcome messages, abandoned cart/cart reminders) from Resilient Labs, Inc at the cell number used when signing up. Consent is not a condition of any purchase. To opt-out of receiving “Resilient Labs, Inc” or “Halo” text messages, text STOP to the number from which you received the message. We will send you one final message to confirm that you have been unsubscribed, and will process your request within a reasonable time after receipt, in accordance with applicable laws. If you experience any issues with text messages from Resilient Labs, Inc, text HELP to the relevant number referenced above or email our Support team at <a href="mailto:support@resilient-labs.com?body=Support Question">support@resilient-labs.com</a>. Carriers are not liable for delayed or undelivered messages. Message frequency varies. Message and data rates may apply.
We maintain “do-not-call” and “do-not-mail” lists as mandated by law. We process requests to be placed on do-not-mail, do-not-phone and do-not-contact lists within the timescales required by law.
Push Notifications. If you opt-in on your device, Resilient Labs, Inc may occasionally send you push notifications through our Apps with updates, achievements and other notices that may be of interest to you. You may at any time opt-out from receiving these types of communications by changing the settings on your device.
</p>
<p className="inter font-[400] text-[15px] md:text-[20px] xl:text-[25px] text-white mt-3">
7.3 "Do Not Track" / Global Privacy Control
</p>
<p className="inter font-[400] text-[15px] md:text-[20px] xl:text-[25px] text-white mt-3">
Do Not Track ("DNT") is a privacy preference that users can set in certain web browsers. DNT is a way for users to inform websites and services that they do not want certain information about their webpage visits collected over time and across websites or online services. Please note that, because there has been no agreed industry standard, we do not respond to or honor DNT signals or similar “do not track” mechanisms transmitted by web browsers.
Please note you may opt out, and we honor, certain technologies broadcasting an Opt-Out Preference Signal, such as the Global Privacy Control (GPC) depending on the state you reside in. This occurs on the browsers and/or browser extensions that support such a signal. This request will be linked to your browser identifier only.
</p>
<p className="inter font-[400] text-[15px] md:text-[20px] xl:text-[25px] text-white mt-3">
7.4 Your rights to access and manage personal information
</p>
<p className="inter font-[400] text-[15px] md:text-[20px] xl:text-[25px] text-white mt-3">
We recognize the importance of your personal information and provide simple ways for you to exercise the following rights: access (right to know), portability, rectification (correction or update), deletion (erasure), object and restrict processing. You can exercise these rights via the following ways:
Update your profile and account settings 
To request that we not serve you recommendations or personalized content based on inferences (described above in section 2 (For Personalization and Advertising) please email us: privacy@resilient-labs.com.
Contact us if you have any questions - please see Section 12 “How To Contact Us”.
</p>
<p className="inter font-[400] text-[15px] md:text-[20px] xl:text-[25px] text-white mt-3">
8. DATA RETENTION
</p>
<p className="inter font-[400] text-[15px] md:text-[20px] xl:text-[25px] text-white mt-3">
Resilient Labs, Inc retains the personal information we receive as described in this Privacy Policy for as long as you use our Services or as necessary to fulfill the purpose(s) for which it was collected, provide our Services, resolve disputes, establish legal defenses, conduct audits, pursue legitimate business purposes, enforce our agreements and comply with applicable laws. CCTV images are retained for 14 days in Germany and 30 days elsewhere. When we are no longer required to retain the personal information as described above, we will destroy, erase, or de-identify it.
Legal requirements, however, may require us to retain some or all of the personal information we hold for a period of time that is longer than that for which we might otherwise hold it.
</p>
<p className="inter font-[400] text-[15px] md:text-[20px] xl:text-[25px] text-white mt-3">
9. SECURITY OF YOUR INFORMATION
</p>
<p className="inter font-[400] text-[15px] md:text-[20px] xl:text-[25px] text-white mt-3">
We take steps to ensure that your information is treated securely and in accordance with this Privacy Policy. We maintain commercially reasonable administrative, technical and physical safeguards (which vary depending on the sensitivity of the personal information) designed to protect against unauthorized use, disclosure or access of personal information.
</p>
<p className="inter font-[400] text-[15px] md:text-[20px] xl:text-[25px] text-white mt-3">
10. LEGAL BASIS FOR PROCESSING
</p>
<p className="inter font-[400] text-[15px] md:text-[20px] xl:text-[25px] text-white mt-3">
Our legal basis for collecting and using the personal information described above will depend on the personal information concerned, applicable laws and the specific context in which we collect it.
However, we will normally collect personal information from you only (i) where we need the personal information to perform a contract with you (such as to provide our Services), (ii) where the processing is in our legitimate interests and not overridden by your rights, or (iii) where we have your consent to do so. In some cases, we may also have a legal obligation to collect personal information from you or may otherwise need the personal information to protect your vital interests or those of another person.
If we ask you to provide personal information to comply with a legal requirement or to perform a contract with you, we will make this clear at the relevant time and advise you whether the provision of your personal information is mandatory or not (as well as of the possible consequences if you do not provide your personal information).
If we collect and use your personal information in reliance on our legitimate interests (or those of any third party), this interest will normally be to operate our Services, to communicate with you as necessary to provide our Services to you, and for our legitimate commercial interests such as responding to your queries, improving our products and Services, undertaking marketing, or detecting and preventing illegal activities. We may have other legitimate interests and we will make clear to you at the relevant time what those legitimate interests are. If you have questions about or need further information concerning the legal basis on which we collect and use your personal information, you can contact us using the details provided under the How To Contact Us section below.
</p>
<p className="inter font-[400] text-[15px] md:text-[20px] xl:text-[25px] text-white mt-3">
11. CHILDREN’S PRIVACY
</p>
<p className="inter font-[400] text-[15px] md:text-[20px] xl:text-[25px] text-white mt-3">
Resilient Labs, Inc Services are not directed to, or intended for, children. We do not knowingly collect personal information directly from children who do not meet the minimum age requirement applicable in your jurisdiction, and if you do not meet the age requirements as specified in our Terms of Service.
If we become aware that we have collected personal data from a child under the minimum age, we promptly delete the personal data.
</p>
<p className="inter font-[400] text-[15px] md:text-[20px] xl:text-[25px] text-white mt-3">
12. HOW TO CONTACT US
</p>
<p className="inter font-[400] text-[15px] md:text-[20px] xl:text-[25px] text-white mt-3">
If you have any questions about our privacy practices, this Privacy Policy or would like to contact us or our Data Protection Officer, you can do so by email at privacy@resilient-labs.com or at the addresses below.
U.S. and Canadian Residents:
</p>
<p className="inter font-[400] text-[15px] md:text-[20px] xl:text-[25px] text-white mt-3">
      <ul>
        <li>Resilient Labs, Inc. </li>
        <li>850 New Burton Rd, Suite 201</li>
        <li>Dover, DE 19904 USA</li>
        <li>Attn: Legal Department</li>
      </ul>
</p>
<p className="inter font-[400] text-[15px] md:text-[20px] xl:text-[25px] text-white mt-3">
You can also complain to a data protection authority about our collection and use of your personal information if you feel that Resilient Labs, Inc has not acted in accordance with applicable laws. For more information, please contact your local data protection authority.
</p>
<p className="inter font-[400] text-[15px] md:text-[20px] xl:text-[25px] text-white mt-3">
13. UPDATES TO OUR PRIVACY POLICY
</p>
<p className="inter font-[400] text-[15px] md:text-[20px] xl:text-[25px] text-white mt-3">
We may update our Privacy Policy from time to time. When we do, we will take appropriate measures to inform you, consistent with the significance of the changes we make. We will obtain your consent to any material Privacy Policy changes if and where this is required by applicable data protection laws. You can see when this Privacy Policy was last updated by checking the "last updated" date displayed at the top of this notice.
</p>
<p>The Halo Mask Smart PPE IoT Medical Inhalants Biotech DoD Department of Defense DARPA n95 Smart Mask technology</p>
<p>The Halo Mask Smart PPE IoT Medical Inhalants Biotech DoD Department of Defense DARPA n95 Smart Mask technology</p>
<p>The Halo Mask Smart PPE IoT Medical Inhalants Biotech DoD Department of Defense DARPA n95 Smart Mask technology</p>
<p>The Halo Mask Smart PPE IoT Medical Inhalants Biotech DoD Department of Defense DARPA n95 Smart Mask technology</p>









        </div>
      </div>

      <img src={PLine} alt="" className="" />
    </>
  );
}
