import React, { useState } from 'react';
import { Link } from "react-router-dom";
import BackGroundImage from "../assets/jpg/background.jpg";



export default function Logincomp() {





    return (
    <>
    <div
    className='bg-cover font-inter bg-no-repeat relative z-0 bg-center h-screen'
    style={{ background: `url(${BackGroundImage})` }} // Correct usage of the imported variable
  >
  <form onSubmit=''>
        <div className='absolute top-0 left-0 w-full h-screen bg-black opacity-50 -z-20'></div>

        <div className='flex items-center justify-center h-screen'>
            <div className='p-8 text-white bg-transparent border border-white rounded-lg shadow-lg w-96'>
                <h1 className='text-4xl font-semibold text-center'>Spiritus Access</h1>
                <div className='flex flex-col mt-2 p-6'>
                        <label className='mb-1 text-xl font-semibold'>Email</label>
                        <input className='px-2 py-1 text-gray-500 bg-transparent border border-white focuse:outline-none focus:ring-0 focus: border-white' type='email' placeholder='Enter Your Email'></input>
                    <div className='flex flex-col mt-2'>
                        <label className='mb-1 text-xl font-semibold'>Password</label>
                        <input className='px-2 py-1 text-gray-500 bg-transparent border border-white focuse:outline-none focus:ring-0 focus: border-white' type='password' placeholder='Enter Password'></input>

                    </div>
                    <div className='flex-justify-between mt-4 text-sm'>
                        <div className='flex flex-col mt-2 text-center'>
                            <Link to="/signup">Don't have an account?</Link>
                            <button className='w-full py-2 mt-6 text-white bg-indigo-700 border-indigo-600 rounded-full hover:bg-transparent hover:text-white'>
                            Login
                            </button>

                        </div>
                        <div className='flex flex-col items-center justify-center mt-2 text-sm gap-4'>
                        <p className='text-sm text-center'>Login using </p>
                            {/* Login Using Facebook  */}   

                          <div className="flex justify-start items-center w-[300px] relative gap-4 pl-4 pr-8 py-3.5 rounded-[100px] bg-white">
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              class="flex-grow-0 flex-shrink-0 w-5 h-5 relative"
                              preserveAspectRatio="xMidYMid meet"
                            >
                              <rect width="20" height="20" rx="10" fill="#1877F2"></rect>
                              <path
                                d="M13.8926 12.8906L14.3359 10H11.5625V8.125C11.5625 7.33398 11.9492 6.5625 13.1914 6.5625H14.4531V4.10156C14.4531 4.10156 13.3086 3.90625 12.2148 3.90625C9.92969 3.90625 8.4375 5.29102 8.4375 7.79688V10H5.89844V12.8906H8.4375V19.8789C8.94727 19.959 9.46875 20 10 20C10.5313 20 11.0527 19.959 11.5625 19.8789V12.8906H13.8926Z"
                                fill="white"
                              ></path>
                            </svg>
                            <p class="flex-grow w-[216px] text-sm font-semibold text-center text-[#121212]">
                              Continue with Facebook
                            </p>
                          </div>





                            {/* Login Using Twitter  */}
                            <div className="flex justify-start items-center w-[300px] relative gap-4 pl-4 pr-8 py-3.5 rounded-[100px] bg-[#121212]">
                            <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            class="flex-grow-0 flex-shrink-0 w-5 h-5 relative"
                            preserveAspectRatio="xMidYMid meet"
                        >
                            <path
                            d="M11.905 8.46953L19.3512 0H17.5869L11.1184 7.35244L5.95583 0H0L7.80863 11.1192L0 20H1.76429L8.59099 12.2338L14.0442 20H20L11.905 8.46953ZM9.48775 11.2168L8.69536 10.1089L2.40053 1.30146H5.11084L10.1925 8.41196L10.9815 9.51988L17.5861 18.7619H14.8758L9.48775 11.2168Z"
                            fill="white"
                            ></path>
                        </svg>
                        <p class="flex-grow w-[216px] text-sm font-semibold text-center text-[#f3f3f3]">
                            Continue with Twitter
                        </p>
                        </div>

                        {/* Login Using Google  */}
                        <div className="flex justify-start items-center w-[300px] relative gap-4 pl-4 pr-8 py-3.5 rounded-[100px] bg-white">
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          class="flex-grow-0 flex-shrink-0 w-5 h-5 relative"
                          preserveAspectRatio="xMidYMid meet"
                        >
                          <g clip-path="url(#clip0_304_559)">
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M19.6 10.2273C19.6 9.51819 19.5364 8.83637 19.4182 8.18182H10V12.05H15.3818C15.15 13.3 14.4455 14.3591 13.3864 15.0682V17.5773H16.6182C18.5091 15.8364 19.6 13.2727 19.6 10.2273Z"
                              fill="#4285F4"
                            ></path>
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M9.99984 20C12.6998 20 14.9635 19.1045 16.618 17.5772L13.3862 15.0681C12.4907 15.6681 11.3453 16.0227 9.99984 16.0227C7.39529 16.0227 5.19075 14.2636 4.40439 11.9H1.06348V14.4909C2.70893 17.7591 6.09075 20 9.99984 20Z"
                              fill="#34A853"
                            ></path>
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M4.40455 11.9C4.20455 11.3 4.09091 10.6591 4.09091 10C4.09091 9.34091 4.20455 8.7 4.40455 8.1V5.50909H1.06364C0.386364 6.85909 0 8.38637 0 10C0 11.6136 0.386364 13.1409 1.06364 14.4909L4.40455 11.9Z"
                              fill="#FBBC05"
                            ></path>
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M9.99984 3.97727C11.468 3.97727 12.7862 4.48182 13.8226 5.47273L16.6907 2.60455C14.9589 0.990909 12.6953 0 9.99984 0C6.09075 0 2.70893 2.24091 1.06348 5.50909L4.40439 8.1C5.19075 5.73636 7.39529 3.97727 9.99984 3.97727Z"
                              fill="#EA4335"
                            ></path>
                          </g>
                          <defs>
                            <clipPath id="clip0_304_559"><rect width="20" height="20" fill="white"></rect></clipPath>
                          </defs>
                        </svg>
                        <p class="flex-grow w-[216px] text-sm font-semibold text-center text-[#121212]">
                          Continue with Google
                        </p>
                      </div>


                        
                        </div>
                        <div className='flex flex-row items-center justify-center mt-2 text-sm '>







                            <p className='text-sm text-center'>Power By Resilient Labs Inc.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </form>
    </div>
    </>
  );
}
