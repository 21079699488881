import React, { useEffect, useState } from "react";
import Navbar from "../component/navbar";
import Line from "../assets/png/line.png";
import Contactuscomp from "../component/contactus";
import Footer from "../component/footer";

export default function Contactus() {
  return (
        <>
          <Navbar />
          <img src={Line} alt="" />
          <Contactuscomp />
          <Footer />
        </>
      );
    }

