import React from "react";
import Line from "../assets/png/line.png";
import PLine from "../assets/png/pline.png";
import { Link } from "react-router-dom";

export default function Careers() {
  return (
    <>

      <div className="grid lg:grid-cols-1 w-[95%] 2xl:w-[60%] mx-auto mt-10 lg:mt-0 ">
        <div className="flex flex-col items-center justify-center xl:self-start xl:mt-14 w-[90%] lg:w-auto mx-auto lg:mx-0">
          <p className="inter font-[400] text-[20px] md:text-[25px] xl:text-[35px] text-white mt-3">CAREERS</p>
          <p className="inter font-[400] text-[15px] md:text-[20px] xl:text-[25px] text-white mt-3">Current Openings</p>
          <p className="inter font-[400] text-[15px] md:text-[20px] xl:text-[25px] text-white mt-3">There are no openings currently by please check back for updates</p>
  
<p>The Halo Mask Smart PPE IoT Medical Inhalants Biotech DoD Department of Defense DARPA n95 Smart Mask technology</p>
<p>The Halo Mask Smart PPE IoT Medical Inhalants Biotech DoD Department of Defense DARPA n95 Smart Mask technology</p>
<p>The Halo Mask Smart PPE IoT Medical Inhalants Biotech DoD Department of Defense DARPA n95 Smart Mask technology</p>
<p>The Halo Mask Smart PPE IoT Medical Inhalants Biotech DoD Department of Defense DARPA n95 Smart Mask technology</p>


        </div>
      </div>

      <img src={PLine} alt="" className="" />
    </>
  );
}
