import React from "react";
import Halo from "../assets/png/halo.png";
import Blogs from "../assets/png/blog.png";
import Line from "../assets/png/line.png";
import PLine from "../assets/png/pline.png";
import Mole from "../assets/png/molecule 1.png";
import Res from "../assets/png/res.png";
import Spir from "../assets/png/spir.png";
import Blog3 from "../assets/png/spiritus 1.png"

import { GoDotFill } from "react-icons/go";

export default function Blog() {
  return (
    <>
      <div className=" grid lg:grid-cols-2 w-[95%] 2xl:w-[60%] mx-auto">
        <div className=" self-center w-[90%] mx-auto">
          <img src={Halo} alt="" className="w-[60%] md:w-[45%] mt-5" />
          <p className="hidden md:block inter font-[700] text-[30px] md:text-[55px] xl:text-[55px] text-white">
            Advanced Smart PPE
          </p>

          <p className="inter font-[400] text-[15px] md:text-[20px] xl:text-[25px] text-white mt-8 flex gap-2">
            <GoDotFill className="self-center" />
            N95 filter capable
          </p>
          <p className="inter font-[400] text-[15px] md:text-[20px] xl:text-[25px] text-white flex gap-2">
            <GoDotFill className="self-center" />
            IoT connected | AI Powered
          </p>
          <p className="inter font-[400] text-[15px] md:text-[20px] xl:text-[25px] text-white flex gap-2">
            <GoDotFill className="self-center" />
            Built in Voice Translation
          </p>
          <p className="inter font-[400] text-[15px] md:text-[20px] xl:text-[25px] text-white flex gap-2">
            <GoDotFill className="mt-1 md:self-center" />
            Inhalant Therapies for Reactive Wellness
          </p>

          <button className="mb-5 w-[90%] block mx-auto md:mx-0 md:w-auto  border-[4px] border-[#3DFAFA] inter font-[400] text-[20px] xl:text-[25px] text-white bg-[#FB35FE] transition duration-500 ease-in-out  hover:bg-[#3DFAFA] py-[5px] px-[18px] rounded-full mt-5">
            Join Our Wait List
          </button>
        </div>
        <div className="mt-14 self-end">
          <img src={Blogs} alt="" className=" xl:w-[75%] lg:ml-auto" />
        </div>
      </div>
      <img src={Line} alt="" className="" />

      <div className="grid lg:grid-cols-2 w-[95%] 2xl:w-[60%] mx-auto mt-10 lg:mt-0 ">
        <div className="hidden lg:block mt-14 lg:w-[90%] mx-auto mb-10">
          <img
            src={Mole}
            alt=""
            className="lg:w-[80%] xl:w-[70%] 2xl:w-[65%]"
          />
        </div>
        <div className="self-center xl:self-start xl:mt-14 w-[90%] lg:w-auto mx-auto lg:mx-0">
          <img src={Res} alt="" className="w-[60%] md:ml-auto" />

          <p className="text-end inter font-[400] text-[15px] md:text-[20px] xl:text-[25px] text-white mt-5">
            Halo Pods are filled with Resilience
          </p>

          <p className="text-end inter font-[400] text-[15px] md:text-[20px] xl:text-[25px] text-white mt-3">
            Our advanced proprietary molecule formulas are created in ISO
            certified for food-safe standard.
          </p>
          <p className="text-end inter font-[400] text-[15px] md:text-[20px] xl:text-[25px] text-white mt-3">
            Molecules to help boost energy, vitality or help you relax and reset
            your calm available to add more resilience to your daily life
            whether in combat or the corporate boardrooms.
          </p>

          <button className="hidden w-[90%] md:block md:ml-auto  md:w-auto  border-[4px] border-[#3DFAFA] inter font-[400] text-[20px] xl:text-[25px] text-white bg-[#FB35FE] transition duration-500 ease-in-out  hover:bg-[#3DFAFA] py-[5px] px-[18px] rounded-full mt-5">
            Join Our Wait List
          </button>
        </div>
        <div className="block lg:hidden mt-10 w-[80%] mx-auto mb-10">
          <img src={Mole} alt="" className="lg:w-[80%] xl:w-[70%]" />
          <button className=" w-[90%] block md:hidden mx-auto border-[4px] border-[#3DFAFA] inter font-[400] text-[20px] xl:text-[30px] text-white bg-[#FB35FE] py-[5px] px-[18px] rounded-full mt-5">
            Join Our Wait List
          </button>
        </div>
      </div>
      <img src={PLine} alt="" className="" />

      <div className="grid lg:grid-cols-2 w-[95%] 2xl:w-[60%] mx-auto mt-10 lg:mt-0 ">
        <div className="self-center xl:self-start xl:mt-14 w-[90%] mx-auto">
          <img src={Spir} alt="" className="w-[40%]" />

          <p className="inter font-[400] text-[15px] md:text-[20px] xl:text-[25px] text-white mt-5">
            Spiritus AI Platform encircles the ecosystems of your life by
            integrating the digital touch points of wellness you already use
            today.
          </p>

          <p className="inter font-[400] text-[15px] md:text-[20px] xl:text-[25px] text-white mt-3">
            We create a digital twin of your wellness universe so you can make
            better decisions of your goals and overall body health decisions.
          </p>
          <p className="inter font-[400] text-[15px] md:text-[20px] xl:text-[25px] text-white mt-3">
            We work with Biotech, Pharma and government healthcare providers to
            improve the solutions to your daily challenges.
          </p>
        </div>

        <div className="">
          <img
            src={Blog3}
            alt=""
            className="w-[70%] lg:w-[70%] xl:w-[60%] 2xl:w-[65%] mx-auto lg:mr-0 lg:ml-auto"
          />
        </div>
      </div>
      <img src={PLine} alt="" className="" />
    </>
  );
}
