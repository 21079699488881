import React, { useEffect, useState } from "react";
import Logincomp from "../component/login";
import { ColorRing } from 'react-loader-spinner';

export default function Login() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000); 

    return () => clearTimeout(timer);
  }, []);


  return (
    <>
      {loading ? (
        <ColorRing
          visible={true}
          height="150"
          width="150"
          colors={['#FB35FE', '#3DFAFA', '#FB35FE', '#3DFAFA', '#FB35FE']}
          ariaLabel="three-circles-loading"
          wrapperStyle={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
          wrapperClass=""
        />
      ) : (
        <>

          <Logincomp />
        </>
      )}
    </>
  );
}
